<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Training Reflection</h4>
        </div>
      </div>
    </div>
    
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- {{ trainers }} -->
              <!-- advance serach -->
              <div class="d-flex">
                    <div class="d-flex flex-column me-2">
                        <label class="col-form-label">Module</label>
                        <div style="width: 180px">
                            <v-select label="name" v-model="module" :options="modules" :clearable="false"
                                :selectable="(options) => module.id != options.id " :loading="!modules.length"
                                @option:selected="FilterData(module, trainer)">
                            </v-select>
                        </div>
                    </div>
                    <div class="d-flex flex-column me-2">
                        <label class="col-form-label">Trainers</label>
                        <div style="width: 180px">
                            <v-select label="name" v-model="trainer" :options="trainers" :clearable="false"
                                :selectable="(options) => trainer.id != options.id " :loading="!trainers.length"
                                @option:selected="FilterData(module, trainer)">
                            </v-select>
                        </div>
                    </div>
                   
                    <div class="d-flex mt-4">
                        <!-- <div class="col-form-label d-none">hfhd</div> -->
                        <div @click="refreshData()" class="icon-css">
                            <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                        </div>
                    </div> 
                </div>  
                        <!--end advance serach -->

            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'training-reflection-create' }">
                <button
                  v-show="!loading"
                  type="button"
                  class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  v-if="hasPermissions(['trainer-training-reflection-create'])"
                >
                  <i class="mdi mdi-plus-circle"></i> Add
                </button>
              </router-link>
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="training-reflection-list-datatable"
                v-show="!loading"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Module Name</th>
                    <th>Batch</th>
                    <th>Trainer</th>
                    <th>Reflection Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in trainingReflection" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.module_name }}</td>
                    <td>{{ item.batch_name }}</td>
                    <td>
                      <span
                        v-for="(trainer, index) in item.trainers"
                        :key="index"
                        >{{ trainer.employee_name }}
                         <span v-if="index + 1 < item.trainers.length">, </span>
                        </span
                      >
                    </td>
                    <td>{{ item.created_at }}</td>
                    <td>
                      <span>
                        <router-link
                          class="action-icon"
                          :to="{
                            name: 'training-reflection-update',
                            params: { id: item.id },
                          }"
                        >
                          <i class="mdi mdi-square-edit-outline"></i>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
// import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
// import badgeDanger from "../../../components/shared/badgeDanger.vue";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
      vSelect
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      trainingReflection: [],
      trainers : [],
      trainer : "",
      modules : [],
      module: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getTrainingRelection() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/training-reflection`)
        .then((response) => {
          this.trainingReflection = response.data.data;
          this.trainingReflection.forEach((item) => {
            item.trainers = JSON.parse(item.trainers);
            const dateTime = new Date(item.created_at);

            // Format the date in your desired format (e.g., 'YYYY-MM-DD HH:mm:ss')
            item.created_at = dateTime.toISOString().slice(0, 10);
          });
          this.loading = false;
          this.$Progress.finish();
          // console.log(this.trainingReflection)
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#training-reflection-list-datatable").DataTable().draw();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#training-reflection-list-datatable").DataTable().draw();
    },
    async getAllModules() {
      await axios
        .get(`${this.baseUrl}admin/v1/modules`)
        .then((response) => {
          this.modules = response.data.data;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
    FilterData(module, trainer) {
        this.clearFilter();
        $.fn.dataTable.ext.search.push(function (settings, data) {
            const check_module = module?.name == data[1];
            const check_trainer = data[3].includes(trainer?.name);
            if((check_module || !module) && (check_trainer || !trainer)){
                return true;
            }
            return false;
        });
        $("#training-reflection-list-datatable").DataTable().draw();
    },
    refreshData() {
      this.trainingReflection = [];
      this.trainer = "";
      this.module = "";
      this.clearFilter();
      this.getTrainingRelection();
    },
    async getHRDemployee() {
      this.trainers = this.$store.getters["odoo/getAllEmployees"].map((item) => {
            return {
                id: item.emp_id,
                name: item.name
            }
      });
    },
  },
  created() {
    this.getHRDemployee();
    this.clearFilter();
    this.getTrainingRelection();
    this.getAllModules()
  
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
  